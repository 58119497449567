import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailHopperPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/hopper-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            UX/UI Design - Add a Feature
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">This was an individual project that was part of the UX/UI Design bootcamp at Ironhack. It consisted in adding a new innovative feature to an existing app, either on Android or iOS, in only 4 days.  </p>
            <p className="mb-4">The briefing for this project was the following: “Add a budget selection in Hopper”. Hopper in an app that predicts prices and helps you book your flights and hotels at the right time, allowing you to make some great savings. </p>
            <p>I used the Design Thinking Framework to guide me in the design process to include this new feature smoothly. If you would like to know more about my process, you can check my medium report.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Ironhack</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">UX/UI Design</p>
            <h2 className = { subtitle }> Documentation </h2>
            <a className="block mb-2 underline" href="https://medium.com/@charlottebotermans/add-a-feature-user-based-research-approach-to-incorporate-a-new-feature-in-an-existing-app-5850050ce44a" target="_blank" rel="noreferrer"> Medium report </a>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2019</p>
          </div>
        </div>



        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3 ">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/hopper-01.png"
            placeholder="blurred"
            loading="eager"
          />
        </div> 

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailHopperPage 